import { Box, Button, Flex, SkeletonText, Text } from '@chakra-ui/react';
import { SchemaOrganizationResponseDto } from '@jam/api-sdk/api';
import { Plus } from '@phosphor-icons/react';
import { createColumnHelper } from '@tanstack/react-table';
import { Chip } from '../../common/components/Chip';
import CustomTable from '../../common/components/custom-table/CustomTable';
import { FilteringToolbar } from '../../common/components/filtering/FilteringToolbar';
import {
	useGetOrganizationsQuery,
	useGetSagasQuery,
} from '../../redux/api/adminApi';

const LoadingState = () => {
	return (
		<Flex role="placeholder" mt="5" direction={'column'} gap="6">
			<Box maxW="300px">
				<SkeletonText noOfLines={1} height={'30px'} />
			</Box>
			<SkeletonText w={'full'} noOfLines={5} spacing={'5'} />
		</Flex>
	);
};

export const OrganizationTable = ({
	onRowClick,
	onAddOrg,
}: {
	onRowClick?: (row: SchemaOrganizationResponseDto) => void;
	onAddOrg?: () => void;
}) => {
	const { data, isLoading } = useGetOrganizationsQuery();
	const { data: sagasData, isLoading: sagaIsLoading } = useGetSagasQuery();

	if (isLoading || sagaIsLoading) return <LoadingState />;

	if (!data || !sagasData) return null;

	const columnHelper = createColumnHelper<SchemaOrganizationResponseDto>();
	const columns = [
		columnHelper.accessor('name', {
			header: 'Name',
			cell: ({ row }) => <Text fontWeight={'medium'}>{row.original.name}</Text>,
		}),
		columnHelper.display({
			header: 'Sagas',
			cell: ({ row }) => {
				const sagas = row.original.sagaIds.map((sagaId) => {
					const saga = sagasData.find((s) => s.id === sagaId);

					return saga?.name ?? sagaId;
				});

				return (
					<>
						{sagas.map((saga, index) => (
							<Chip key={index} text={saga} />
						))}
					</>
				);
			},
		}),
	];

	return (
		<CustomTable<SchemaOrganizationResponseDto, { name: string }>
			data={data}
			columns={columns}
			renderFilterBar={(columnFilters, setColumnFilters) => (
				<Flex gap="5">
					<FilteringToolbar
						filters={[
							{
								id: 'email',
								type: 'text',
								placeholder: 'Organization Name',
								onChange: (value) =>
									setColumnFilters({ ...columnFilters, name: value }),
								value: columnFilters.name ?? '',
							},
						]}
					/>
					{onAddOrg && (
						<Button
							id="add-org-button"
							leftIcon={<Plus size={15} />}
							type="submit"
							px="6"
							bgColor={'brand.500'}
							size="md"
							onClick={onAddOrg}
						>
							Add organization
						</Button>
					)}
				</Flex>
			)}
			onRowClick={(item) => {
				onRowClick?.(item);
			}}
		/>
	);
};
