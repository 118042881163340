import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

interface InformationGoalProps {
	icon: React.ReactNode;
	title: string;
	description?: string;
	variant?: 'default' | 'primary';
}

const stylesMap: Record<
	'primary' | 'default',
	Record<'borderColor' | 'backgroundColor' | 'textColor' | 'titleColor', string>
> = {
	default: {
		borderColor: '#D9D9D9',
		backgroundColor: '#FAFAFA',
		textColor: '#757575',
		titleColor: '#0B1215',
	},
	primary: {
		borderColor: '#4241e414',
		backgroundColor: '#4241e414',
		textColor: '#4241e4',
		titleColor: '#0B1215',
	},
};

export const InformationCard: React.FC<InformationGoalProps> = ({
	icon,
	title,
	description,
	variant = 'default',
}) => {
	const styles = stylesMap[variant] ?? stylesMap.default;

	return (
		<Flex
			direction="row"
			alignItems="center"
			gap="12px"
			borderRadius="24px"
			border={`1px solid ${styles.borderColor}`}
			padding="16px"
			backgroundColor={styles.backgroundColor}
		>
			<Box>{icon}</Box>
			<Flex direction="column" gap="8px" justifyContent="center">
				<Text margin={0} color={styles.titleColor}>
					{title}
				</Text>
				{description && (
					<Text color={styles.textColor} margin={0}>
						{description}
					</Text>
				)}
			</Flex>
		</Flex>
	);
};
