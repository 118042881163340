import { Avatar, Box, Flex, Skeleton, Text } from '@chakra-ui/react';
import { SchemaUserPerformanceResponseDto } from '@jam/api-sdk/api';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import CustomTable from '../../../../../common/components/custom-table/CustomTable';
import { FilteringToolbar } from '../../../../../common/components/filtering/FilteringToolbar';
import { useGetDetailedUserPerformanceQuery } from '../../../../../redux/api/dashboardApi';

const LoadingSkeleton = () => {
	return <Skeleton role="placeholder" height="20px" />;
};

export const UserPerformanceTable = () => {
	const { t } = useTranslation('dashboard');
	const { userId } = useParams();

	const { data: performanceData, isLoading } =
		useGetDetailedUserPerformanceQuery(userId as string, {
			skip: !userId,
		});

	if (isLoading) {
		return <LoadingSkeleton />;
	}

	if (!performanceData) {
		return null;
	}

	const columnHelper = createColumnHelper<SchemaUserPerformanceResponseDto>();

	const tracks = [
		...new Set(performanceData.map((performance) => performance.trackName)),
	];

	const columns = [
		columnHelper.accessor('missionName', {
			enableSorting: false,
			header: t('user_details.performance_table.mission') ?? '',
			cell: (info) => {
				return (
					<Flex alignItems={'center'} gap="2">
						<Avatar size="sm" src={info.row.original.personaImage} />
						<Flex direction={'column'}>
							<Text>{info.getValue()}</Text>
							<Text fontSize="12px" color="#757575">
								{info.row.original.personaName}
							</Text>
						</Flex>
					</Flex>
				);
			},
		}),
		columnHelper.accessor('trackName', {
			header: t('user_details.performance_table.track') ?? '',
			enableSorting: false,
			cell: (info) => info.getValue(),
		}),
		columnHelper.accessor('roundsPlayed', {
			header: t('user_details.performance_table.rounds_played') ?? '',
			enableSorting: false,
			cell: (info) => info.getValue(),
		}),
		columnHelper.accessor('completions', {
			header: t('user_details.performance_table.completions_rounds') ?? '',
			enableSorting: false,
			cell: (info) => `${info.getValue()} / ${info.row.original.roundsPlayed}`,
		}),
		columnHelper.accessor('averageScore', {
			header: t('user_details.performance_table.average_score') ?? '',
			enableSorting: false,
			cell: (info) => info.getValue(),
		}),
		columnHelper.accessor('numberOfRoundsToComplete', {
			header: t('user_details.performance_table.rounds_to_complete') ?? '',
			enableSorting: false,
			cell: (info) => info.getValue(),
		}),
	];

	return (
		<Box mt={5}>
			<CustomTable<
				SchemaUserPerformanceResponseDto,
				{ missionName: string; trackName: string }
			>
				renderFilterBar={(columnFilters, setColumnFilters) => (
					<FilteringToolbar
						filters={[
							{
								id: 'missionName',
								type: 'text',
								placeholder:
									t(
										'user_details.performance_table.search_mission_placeholder'
									) ?? '',
								onChange: (value) =>
									setColumnFilters({ ...columnFilters, missionName: value }),
								value: columnFilters.missionName ?? '',
							},
							{
								id: 'trackName',
								type: 'select',
								options: [
									{
										label: t('user_details.performance_table.all_tracks'),
										value: '',
									},
									...tracks.map((track) => ({
										label: track,
										value: track,
									})),
								],
								onChange: (value) =>
									setColumnFilters({ ...columnFilters, trackName: value }),
								value: columnFilters.trackName ?? '',
							},
						]}
					/>
				)}
				withWrapper
				data={performanceData}
				columns={columns}
				paginationPosition="bottom"
			/>
		</Box>
	);
};
