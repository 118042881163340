import { Box, Flex, Image, Text, Tooltip } from '@chakra-ui/react';
import React, { ElementType, ReactElement } from 'react';

interface CallToActionCardProps {
	image: string;
	imageHeader?: React.ReactNode;
	imageFooter?: React.ReactNode;
	title: string;
	description: string;
	descriptionIcon?: ElementType;
	descriptionSubContent?: React.ReactNode;
	storyActionButton?: ReactElement;
	height?: string;
	width?: string;
	hideTooltip?: boolean;
}

export const CallToActionCard: React.FC<CallToActionCardProps> = ({
	image,
	imageHeader,
	imageFooter,
	title,
	description,
	descriptionSubContent,
	descriptionIcon,
	height,
	width,
	hideTooltip,
	storyActionButton,
}) => {
	const Icon = descriptionIcon;
	return (
		<Flex
			direction="column"
			alignItems="start"
			gap="12px"
			borderRadius="12px"
			border="1px solid #D9D9D9"
			boxShadow="0px 4px 10px 0px #0000000d"
			position="relative"
			height={height}
			width={width}
		>
			<Box position="relative" height="240px" width="100%">
				{imageHeader && (
					<Box
						position="absolute"
						top="6px"
						left="0"
						width="100%"
						paddingX={'6px'}
					>
						{imageHeader}
					</Box>
				)}
				{imageFooter && (
					<Box
						position="absolute"
						bottom="6px"
						left="0"
						width="100%"
						paddingX={'6px'}
					>
						{imageFooter}
					</Box>
				)}
				{image && (
					<Image
						w={'100%'}
						maxH={'240px'}
						borderTopRadius={'12px'}
						src={image}
						objectFit={'cover'}
					/>
				)}
			</Box>

			<Flex
				direction="column"
				alignItems="start"
				gap="12px"
				paddingLeft="12px"
				paddingRight="12px"
				paddingBottom="12px"
				maxW="100%"
				flex="1"
			>
				{/* Fixed height container for title */}
				<Box height="48px" width="100%">
					<Text
						margin={0}
						fontSize="16px"
						fontWeight={500}
						color="#0B1215"
						noOfLines={2}
					>
						{title}
					</Text>
				</Box>

				{/* Fixed height container for description */}
				<Box height="40px" width="100%">
					<Flex direction="row" gap="3px" maxW="100%">
						{Icon && (
							<Box mt="1">
								<Icon color="#757575" size="12px" />
							</Box>
						)}
						{description && (
							<Tooltip label={description} isDisabled={hideTooltip}>
								<Text
									color="#757575"
									fontSize="14px"
									margin={0}
									noOfLines={1}
									maxW="90%"
								>
									{description}
								</Text>
							</Tooltip>
						)}
					</Flex>
					{/* Container for optional description subcontent */}
					{descriptionSubContent}
				</Box>

				{/* Push button to bottom */}
				<Box marginTop="auto" width="100%">
					{storyActionButton}
				</Box>
			</Flex>
		</Flex>
	);
};
