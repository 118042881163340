import {
	Box,
	Flex,
	Table,
	TableColumnHeaderProps,
	TableProps,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import { ArrowDown, ArrowUp, ArrowsDownUp } from '@phosphor-icons/react';
import {
	Column,
	RowData,
	Table as TableType,
	flexRender,
} from '@tanstack/react-table';

declare module '@tanstack/react-table' {
	interface ColumnMeta<TData extends RowData, TValue> {
		name: string;
		hideTitle?: boolean;
		customFilter?: (props: { column: Column<TData, TValue> }) => JSX.Element;
	}
}

type TableComponentProps<T> = {
	table: TableType<T>;
	tableProps?: TableProps;
	headerProps?: TableColumnHeaderProps;
	onRowClick?: (row: T) => void;
};

const TableComponent = <T,>({
	table,
	tableProps,
	headerProps,
	onRowClick = () => {},
}: TableComponentProps<T>) => {
	return (
		<Table width="100%" {...tableProps}>
			<Thead>
				{table.getHeaderGroups().map((headerGroup) => (
					<Tr key={headerGroup.id}>
						{headerGroup.headers.map((header) => {
							return (
								<Th
									borderTop={'0px'}
									key={header.id}
									bg={'white'}
									colSpan={header.colSpan}
									whiteSpace="break-spaces"
									{...headerProps}
								>
									{header.column.columnDef.meta?.hideTitle ? null : (
										<>
											<Flex alignItems={'center'}>
												<Box
													display={'flex'}
													alignItems={'center'}
													textColor={'#757575'}
													fontWeight={'medium'}
													onClick={header.column.getToggleSortingHandler()}
												>
													{flexRender(
														header.column.columnDef.header,
														header.getContext()
													)}
													{header.column.getCanSort() && (
														<>
															{{
																asc: <ArrowUp height={'15px'} width={'15px'} />,
																desc: (
																	<ArrowDown height={'15px'} width={'15px'} />
																),
															}[header.column.getIsSorted() as string] ?? (
																<ArrowsDownUp height={'15px'} width={'15px'} />
															)}
														</>
													)}
												</Box>
												{header.column.columnDef.meta?.customFilter ? (
													<Box ml="4">
														{header.column.columnDef.meta.customFilter({
															column: header.column,
														})}
													</Box>
												) : null}
											</Flex>
										</>
									)}
								</Th>
							);
						})}
					</Tr>
				))}
			</Thead>
			<Tbody>
				{table.getRowModel().rows.map((row) => (
					<Tr
						key={row.id}
						cursor={'pointer'}
						onClick={() => {
							onRowClick(row.original);
						}}
					>
						{row.getVisibleCells().map((cell) => (
							<Td key={cell.id} whiteSpace="break-spaces">
								{flexRender(cell.column.columnDef.cell, cell.getContext())}
							</Td>
						))}
					</Tr>
				))}
			</Tbody>
		</Table>
	);
};

export default TableComponent;
