import { PendingInviteResponseDTOStatus } from '@jam/api-sdk/api';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from '../../../core/Tag';

const statusStylesMap: Record<
	PendingInviteResponseDTOStatus,
	{ bg: string; color: string; text: string }
> = {
	[PendingInviteResponseDTOStatus.pending]: {
		bg: '#4241e414',
		color: '4241E4',
		text: 'team:invite.pending',
	},
	[PendingInviteResponseDTOStatus.expired]: {
		bg: '#FF00000D',
		color: '#FF4C4C',
		text: 'team:invite.expired',
	},
};

export const InviteStatusCell: React.FC<{
	row: {
		status: PendingInviteResponseDTOStatus;
	};
}> = ({ row }) => {
	const { t } = useTranslation();
	const styles = statusStylesMap[row.status] ?? {};
	return (
		<Tag label={t(styles.text)} textColor={styles.color} bgColor={styles.bg} />
	);
};
