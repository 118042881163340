import {
	Accordion,
	AccordionButton,
	AccordionItem,
	AccordionPanel,
	Box,
	Divider,
	Flex,
	SimpleGrid,
	Text,
} from '@chakra-ui/react';
import { SchemaJourneyResponseDto } from '@jam/api-sdk/api';
import {
	CaretDown,
	CaretUp,
	Circle,
	ThumbsDown,
	ThumbsUp,
} from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
type MissionTaskListProps = {
	tasks: SchemaJourneyResponseDto['stories'][0]['items_conversation_feedback'];
};

export const MissionTaskList = ({ tasks }: MissionTaskListProps) => {
	const { t } = useTranslation('common');
	return (
		<Flex direction="column" gap="4">
			{tasks.map((task, index) => (
				<Accordion
					w={'100%'}
					borderWidth={'1.5px'}
					p={6}
					borderRadius={'12px'}
					borderColor={'#d9d9d9'}
					key={task.id}
					allowMultiple
				>
					<AccordionItem border={0}>
						{({ isExpanded }) => (
							<>
								<AccordionButton
									px={3}
									py={0}
									_hover={{ background: 'transparent' }}
								>
									<Flex w={'100%'}>
										<Flex justify={'space-between'} w="100%" gap={10}>
											<Flex
												direction={'column'}
												flexGrow={1}
												gap={3}
												w={'100%'}
											>
												<Flex alignItems={'center'} gap={2}>
													<Flex
														borderWidth={'1px'}
														borderColor={'#D9d9d9'}
														color={'#757575'}
														fontSize={'12px'}
														w={'24px'}
														h={'24px'}
														justifyContent={'center'}
														alignItems={'center'}
														borderRadius={'full'}
													>
														{index + 1}
													</Flex>
													<Text fontSize="20px" fontWeight="500">
														{task.scorecard_title}
													</Text>
												</Flex>
												{task.item_goal && (
													<Flex direction={'column'}>
														<Text
															w={'fit-content'}
															color="#0B1215"
															fontWeight={'500'}
															fontSize={'16px'}
														>
															{t('objective')}
														</Text>
														<Text
															w={'fit-content'}
															color="#757575"
															fontSize={'16px'}
														>
															{task.item_goal}
														</Text>
													</Flex>
												)}
											</Flex>
											<Flex alignItems={'center'} gap={2}>
												{isExpanded ? (
													<CaretUp size={20} weight="bold" />
												) : (
													<CaretDown size={20} weight="bold" />
												)}
											</Flex>
										</Flex>
									</Flex>
								</AccordionButton>
								<AccordionPanel px={0} mx={0}>
									<Divider my={3} />
									<SimpleGrid columns={2} gap={4}>
										<Flex gap={3} direction={'column'}>
											<Flex gap={2} alignItems={'center'}>
												<Flex
													bgColor={'gray.50'}
													fontSize={'12px'}
													w={'24px'}
													h={'24px'}
													justifyContent={'center'}
													alignItems={'center'}
													borderRadius={'full'}
												>
													<ThumbsUp size={15} weight="fill" />
												</Flex>
												<Text
													color={'#0B1215'}
													fontSize={'16px'}
													fontWeight={'500'}
												>
													{t('dos')}
												</Text>
											</Flex>
											{task.desired_behaviour && (
												<Flex pl={'10'} direction={'column'}>
													{task.desired_behaviour.map((behaviour, i) => (
														<Flex gap={3} key={i}>
															<Box mt={'10px'}>
																<Circle
																	color="#757575"
																	size={7}
																	weight="fill"
																/>
															</Box>
															<Text
																color="#757575"
																fontSize={'16px'}
																lineHeight={'24px'}
															>
																{behaviour.behaviour}
															</Text>
														</Flex>
													))}
												</Flex>
											)}
										</Flex>
										<Flex gap={3} direction={'column'}>
											<Flex gap={2} alignItems={'center'}>
												<Flex
													bgColor={'gray.50'}
													fontSize={'12px'}
													w={'24px'}
													h={'24px'}
													justifyContent={'center'}
													alignItems={'center'}
													borderRadius={'full'}
												>
													<ThumbsDown size={15} weight="fill" />
												</Flex>
												<Text
													color={'#0B1215'}
													fontSize={'16px'}
													fontWeight={'500'}
												>
													{t('donts')}
												</Text>
											</Flex>
											{task.undesired_behaviour && (
												<Flex pl={'10'} direction={'column'}>
													{task.undesired_behaviour.map((behaviour, i) => (
														<Flex gap={3} key={i}>
															<Box mt={'10px'}>
																<Circle
																	color="#757575"
																	size={7}
																	weight="fill"
																/>
															</Box>
															<Text
																color="#757575"
																fontSize={'16px'}
																lineHeight={'24px'}
															>
																{behaviour.behaviour}
															</Text>
														</Flex>
													))}
												</Flex>
											)}
										</Flex>
									</SimpleGrid>
								</AccordionPanel>
							</>
						)}
					</AccordionItem>
				</Accordion>
			))}
		</Flex>
	);
};
