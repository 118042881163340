import { Flex, Image, Text } from '@chakra-ui/react';
import { Article, Clock } from '@phosphor-icons/react';
import { format } from 'date-fns';
import { de } from 'date-fns/locale/de';
import { enGB } from 'date-fns/locale/en-GB';
import { useTranslation } from 'react-i18next';
import { useDifficultyIcon } from '../../../features/home/hooks/useDifficultyIcon';
import { useGetJourneysQuery } from '../../../redux/api/cmsApi';
import { StoryStatus, useStoryStatus } from '../../hooks/useStoryStatus';
import { Chip } from '../Chip';
import { StoryCardImageHeader } from './StoryCardImageHeader';
import {
	SchemaStoryResponseDto,
	AssignedContentResponseDTOType,
} from '@jam/api-sdk/api';
import { ReactElement } from 'react';
import { CallToActionCard } from '../core/cards/information/CallToActionCard';

export type StoryCardProps = {
	story: SchemaStoryResponseDto;
	type?: AssignedContentResponseDTOType;
	dueDate?: Date;
	userId?: string;
	hideStatus?: boolean;
	renderStoryButton?: (storyId: string, status?: StoryStatus) => ReactElement;
};

export const StoryCard = ({
	story,
	dueDate,
	userId,
	hideStatus,
	renderStoryButton,
}: StoryCardProps) => {
	const { t } = useTranslation(['story', 'history', 'common']);
	const { i18n } = useTranslation();
	const { data: journeys } = useGetJourneysQuery();
	const difficultyIcon = useDifficultyIcon(story.difficulty);
	const status = useStoryStatus(story.id, userId);

	const locale = i18n.language === 'en' ? enGB : de;
	const image =
		story.persona.avatar_file.sizes.thumbnail?.url ??
		story.persona.avatar_file.url;
	const journey = journeys?.find((j) =>
		j.stories.some((s) => s.id === story.id)
	);
	const dueDateText = dueDate
		? t('common:due_date', {
				date: format(dueDate, 'MMM d, yyyy', {
					locale,
				}),
			})
		: '';
	const journeyTitle = journey?.title ?? '';

	return (
		<>
			<Flex data-testid="story-card" w="full" maxW="284px">
				<CallToActionCard
					height="100%"
					width="100%"
					image={image}
					title={story.title}
					description={journeyTitle}
					descriptionIcon={Article}
					hideTooltip={true}
					descriptionSubContent={
						<Text fontSize="12px" margin={0}>
							{dueDateText}
						</Text>
					}
					storyActionButton={
						renderStoryButton ? renderStoryButton(story.id, status) : undefined
					}
					imageHeader={
						<StoryCardImageHeader status={status} hideStatus={hideStatus} />
					}
					imageFooter={
						<Flex width="100%" justifyContent="space-between">
							{story.duration && (
								<Chip
									px="1"
									py="2px"
									h="24px"
									bg={'#F5F5F5'}
									border={0}
									borderRadius={'4px'}
									gap={'4px'}
									fontSize={'14px'}
									color="#757575"
									icon={<Clock size={14} color="#757575" />}
									text={t('duration_value', {
										duration: story.duration,
									})}
									aria-label="Story duration"
								/>
							)}

							{story.difficulty && (
								<Chip
									px="1"
									py="2px"
									h="24px"
									bg={'#F5F5F5'}
									borderRadius={'4px'}
									fontSize={'14px'}
									gap={'4px'}
									color="#757575"
									border={0}
									icon={difficultyIcon ? <Image src={difficultyIcon} /> : null}
									text={t(story.difficulty)}
									aria-label="Story difficulty"
								/>
							)}
						</Flex>
					}
				/>
			</Flex>
		</>
	);
};
