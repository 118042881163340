import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Divider,
	Text,
} from '@chakra-ui/react';
import { SchemaJourneyResponseDto } from '@jam/api-sdk/api';
import { TrackDetailHeader } from './TrackDetailHeader';
import { useTranslation } from 'react-i18next';
import { StoryCardCarousel } from '../story-detail/StoryCardCarousel';
import { ReactElement } from 'react';
import { StoryStatus } from '../../hooks/useStoryStatus';

type TrackAccordionProps = {
	journey: SchemaJourneyResponseDto;
	completedStoryIds?: string[];
	userId?: string;
	hideStatus?: boolean;
	renderStoryButton?: (storyId: string, status?: StoryStatus) => ReactElement;
};

export const TrackAccordion = ({
	journey,
	completedStoryIds,
	userId,
	hideStatus,
	renderStoryButton,
}: TrackAccordionProps) => {
	const { t } = useTranslation('common');

	return (
		<Box p="6" border="1px" borderColor="#d9d9d9" borderRadius="12px">
			<Accordion allowToggle>
				<AccordionItem border={0}>
					<TrackDetailHeader
						hideStatus={hideStatus}
						journey={journey}
						completedStoryIds={completedStoryIds}
					/>
					<Divider color="#d9d9d9" />
					<AccordionButton
						px={0}
						paddingBottom="0px"
						paddingTop="16px"
						_hover={{ background: 'transparent' }}
					>
						<Text fontSize="16px" fontWeight="500" textColor="#4241E4">
							{t('track_detail.accordion.view_missions')}
						</Text>
						<AccordionIcon color="#4241E4" />
					</AccordionButton>
					<AccordionPanel px={0} py={0}>
						<StoryCardCarousel
							hideStatus={hideStatus}
							userId={userId}
							stories={journey.stories}
							renderStoryButton={renderStoryButton}
						/>
					</AccordionPanel>
				</AccordionItem>
			</Accordion>
		</Box>
	);
};
