import { Flex } from '@chakra-ui/react';
import { SchemaStoryResponseDto } from '@jam/api-sdk/api';
import { StoryCard } from './StoryCard';
import { ReactElement } from 'react';
import { StoryStatus } from '../../hooks/useStoryStatus';

type StoryCardCarrouselProps = {
	stories: SchemaStoryResponseDto[];
	dueDate?: Date;
	userId?: string;
	hideStatus?: boolean;
	renderStoryButton?: (storyId: string, status?: StoryStatus) => ReactElement;
};

export const StoryCardCarousel = ({
	stories,
	dueDate,
	userId,
	hideStatus,
	renderStoryButton,
}: StoryCardCarrouselProps) => {
	return (
		<Flex direction="row" gap="2" overflow="auto" pb="4" pt="4">
			{stories.map((s: SchemaStoryResponseDto) => {
				return (
					<StoryCard
						key={s.id}
						dueDate={dueDate}
						story={s}
						userId={userId}
						hideStatus={hideStatus}
						renderStoryButton={renderStoryButton}
					/>
				);
			})}
		</Flex>
	);
};
