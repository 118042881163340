import { Box, Flex, Text } from '@chakra-ui/react';
import { useAuthInfo } from '@propelauth/react';
import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../redux/slice';
import { useIsAdmin } from '../hooks/useIsAdmin';
import { useIsMobile } from '../hooks/useIsMobile';
import { CenteredMessagePageWrapper } from './CenteredMessagePageWrapper';
import { ReportIssueModal } from './ReportIssueModal';
import { Sidebar } from './SideBar';
import { AdminHeader } from './admin/AdminHeader';

type AppLayoutProps = {
	children: React.ReactNode;
	hideSidebar?: boolean;
	fullWidth?: boolean;
};

export const AppLayout = ({
	children,
	hideSidebar,
	fullWidth,
}: AppLayoutProps) => {
	const { user } = useAuthInfo();
	const { i18n, t } = useTranslation('common');
	const isAdmin = useIsAdmin();
	const language = useSelector(selectLanguage);

	const isOnMobile = useIsMobile();

	useEffect(() => {
		if (user) {
			Sentry.setUser({
				email: user.email,
			});
		}
	}, [user]);

	if (i18n.language !== language) return null;

	if (isOnMobile) {
		return (
			<CenteredMessagePageWrapper>
				<Text sx={{ fontSize: 18, lineHeight: '28px', fontWeight: 700 }}>
					{t('mobile_access_not_supported.title')}
				</Text>
				<Text variant="subtitle1">
					{t('mobile_access_not_supported.message')}
				</Text>
				<Text variant="subtitle1">
					{t('mobile_access_not_supported.subtitle')}
				</Text>
			</CenteredMessagePageWrapper>
		);
	}

	return (
		<>
			{isAdmin && <AdminHeader />}
			<Flex as="section" h="100vh" bg={'white'} gap={3}>
				{!hideSidebar && <Sidebar />}
				<ReportIssueModal />

				<Flex
					flexShrink={1}
					direction={'column'}
					gap="24px"
					w="100%"
					bg={'white'}
					overflowY={'auto'}
					py={{ base: '10px', md: '40px' }}
					px={{ base: '10px', md: '20px' }}
				>
					<Box w="100%" mx={'auto'} maxW={fullWidth ? 'full' : '1156px'}>
						{children}
					</Box>
				</Flex>
			</Flex>
		</>
	);
};
