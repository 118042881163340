import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import './index.css';
import reportWebVitals from './utils/reportWebVitals';
// import i18n to bundle it
import './utils/i18n';

import * as Sentry from '@sentry/react';
import ErrorPage from './pages/ErrorPage';

import { ChakraProvider } from '@chakra-ui/react';
import { RedirectToLogin, RequiredAuthProvider } from '@propelauth/react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { appRoutes } from './routes';
import { customTheme } from './theme/chakraTheme';
import { initLogger } from './utils/logger';
import { createBrowserRouterFunction, initSentry } from './utils/sentry';

const authUrl = (import.meta.env.VITE_AUTH_URL as string) ?? '';

initLogger();
initSentry();

const router = createBrowserRouterFunction()(appRoutes);

// render react into root dom element
const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<Provider store={store}>
		<Sentry.ErrorBoundary>
			<ChakraProvider theme={customTheme}>
				<Suspense fallback="loading">
					<RequiredAuthProvider
						authUrl={authUrl}
						displayWhileLoading={<h1>Loading...</h1>}
						displayIfLoggedOut={
							<RedirectToLogin postLoginRedirectUrl={window.location.href} />
						}
					>
						<RouterProvider router={router} fallbackElement={<ErrorPage />} />
					</RequiredAuthProvider>
				</Suspense>
			</ChakraProvider>
		</Sentry.ErrorBoundary>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an manager-analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line @typescript-eslint/no-floating-promises
reportWebVitals();
