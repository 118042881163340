/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Box,
	Flex,
	Skeleton,
	Text,
	Tooltip as ChakraTooltip,
} from '@chakra-ui/react';
import {
	CartesianGrid,
	YAxis,
	XAxis,
	Line,
	LineChart,
	ResponsiveContainer,
	Tooltip,
	DotProps,
} from 'recharts';
import {
	UserPerformanceByRound,
	useGetUserPerformanceByRound,
} from '../../../hooks/useGetUserPerformanceByRound';
import { useState } from 'react';
import { useGetStoriesQuery } from '../../../../../redux/api/cmsApi';
import { FilteringToolbar } from '../../../../../common/components/filtering/FilteringToolbar';
import { Info } from '@phosphor-icons/react';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LoadingSkeleton = () => {
	return <Skeleton role="placeholder" height="20px" />;
};

interface UserPerformanceChartProps {
	userId: string | undefined;
	userName: string | undefined;
}

interface CustomDotProps extends DotProps {
	payload?: UserPerformanceByRound;
}

export const CustomDot = (props: CustomDotProps) => {
	const { cx, cy, payload } = props;
	if (!cx || !cy) return <></>;

	if (payload?.score === 100) {
		return (
			<svg
				x={cx - 7}
				y={cy - 7}
				width={14}
				height={14}
				fill="#2B9A5E"
				viewBox="0 0 24 24"
				style={{ stroke: 'white', strokeWidth: 2 }}
			>
				<path d="M12 0a12 12 0 100 24 12 12 0 000-24zm6.7 8.7l-8 8c-.2.2-.4.3-.7.3s-.5-.1-.7-.3l-4-4c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l3.3 3.3 7.3-7.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4z" />
			</svg>
		);
	}

	return (
		<circle
			cx={cx}
			cy={cy}
			r={7}
			style={{ stroke: 'white', strokeWidth: 2 }}
			fill="#4241E4"
		/>
	);
};

export const CustomTooltip = ({ active, payload }: any) => {
	const navigate = useNavigate();
	const { t } = useTranslation('dashboard');

	if (!active || !payload?.length) return null;
	const { session } =
		(payload[0].payload as UserPerformanceByRound | undefined) ?? {};
	if (!session || !session.id) return <></>;
	const { userId, id } = session;
	return (
		<Box bg="white" p="3" pr="10" borderRadius="16px" boxShadow="md">
			<Text fontSize="sm" color="gray.600">
				{t('user_details.performance_chart.score')}: {payload[0].value}
			</Text>
			{typeof session?.totalItems === 'number' && (
				<Text fontSize="sm" color="gray.600">
					{t('user_details.performance_chart.tasks_solved', {
						completed: session.completedItems,
						total: session.totalItems,
					})}
				</Text>
			)}
			{session?.date && (
				<Text fontSize="sm" color="gray.600">
					{t('user_details.performance_chart.date')}:{' '}
					{format(new Date(session.date), 'MMM dd, yyyy')}
				</Text>
			)}

			<Text
				onClick={() => navigate(`/dashboard/user/${userId}/session/${id}`)}
				cursor={'pointer'}
				mt="2"
				fontWeight="500"
				fontSize="14px"
				color="#4241E4"
			>
				{t('user_details.performance_chart.view_feedback')}
			</Text>
		</Box>
	);
};

export const UserPerformanceChart = ({
	userId,
	userName,
}: UserPerformanceChartProps) => {
	const [missionId, setMissionId] = useState<string | undefined>(undefined);
	const { t } = useTranslation('dashboard');

	const { data: missions } = useGetStoriesQuery();
	const performanceData = useGetUserPerformanceByRound(userId, missionId);

	if (!performanceData || !missions) return <LoadingSkeleton />;

	const selectedMission = missions.find((mission) => mission.id === missionId);

	const allEmpty = performanceData.every((item) => item.score === null);

	return (
		<Flex direction={{ base: 'column' }} gap="5">
			<Text fontSize={'20px'} fontWeight={'500'}>
				{t('user_details.performance_chart.compare_performance', { userName })}
			</Text>
			<FilteringToolbar
				filters={[
					{
						id: 'missionId',
						type: 'select',
						options: [
							{
								label: t('user_details.performance_chart.all_missions'),
								value: '',
							},
							...missions.map((mission) => ({
								label: mission.title,
								value: mission.id,
							})),
						],
						onChange: (value) => setMissionId(value),
						value: missionId ?? '',
					},
				]}
			/>
			<Box
				h={'450px'}
				p="5"
				borderRadius="16px"
				border="1px solid #D9D9D9"
				position="relative"
			>
				{allEmpty && (
					<Flex
						position="absolute"
						top="0"
						left="0"
						right="0"
						bottom="0"
						bg="rgba(0, 0, 0, 0.05)"
						alignItems="center"
						justifyContent="center"
						borderRadius="16px"
					>
						<Text color="gray.600">
							{t('user_details.performance_chart.no_missions_played')}
						</Text>
					</Flex>
				)}
				<Text mb="3" fontSize={'14px'} fontWeight={'500'} color="#0B1215">
					{selectedMission
						? selectedMission?.title
						: t('user_details.performance_chart.all_missions')}
				</Text>
				<Flex justify={'space-between'} align={'center'}>
					<Flex gap="1" direction="column-reverse" alignItems="center">
						<Text
							textAlign={'right'}
							fontSize={'12px'}
							color="#757575"
							style={{
								writingMode: 'vertical-rl',
								transform: 'rotate(180deg)',
							}}
						>
							{t('user_details.performance_chart.total_score')}
						</Text>
						<ChakraTooltip
							label={t('user_details.performance_chart.total_score_helper')}
						>
							<Info color="#757575" size={16} />
						</ChakraTooltip>
					</Flex>
					<ResponsiveContainer width={'100%'} height={350}>
						<LineChart
							data={performanceData}
							margin={{ top: 5, right: 30, left: 0, bottom: 5 }}
						>
							<CartesianGrid horizontal={false} />
							<Tooltip
								wrapperStyle={{ pointerEvents: 'auto' }}
								trigger="click"
								content={<CustomTooltip />}
							/>
							<XAxis
								dataKey="round"
								tickLine={false}
								axisLine={false}
								width={0}
							/>
							<YAxis
								axisLine={false}
								tickLine={false}
								domain={[0, 105]} // set to 105 for 100 icon to be fully visible
								ticks={[0, 20, 40, 60, 80, 100]}
							/>
							<Line
								type={'linear'}
								dataKey="score"
								stroke="#4241E4"
								dot={<CustomDot />}
							/>
						</LineChart>
					</ResponsiveContainer>
				</Flex>
				<Flex
					mt="2"
					gap="1"
					justifyContent="flex-end"
					fontSize={'12px'}
					color="#757575"
				>
					<Text>{t('user_details.performance_chart.rounds_played')}</Text>
					<ChakraTooltip
						label={t('user_details.performance_chart.rounds_played_helper')}
					>
						<Info color="#757575" size={16} />
					</ChakraTooltip>
				</Flex>
			</Box>
		</Flex>
	);
};
