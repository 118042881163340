import {
	Tab,
	TabIndicator,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { AppLayout } from '../common/components/AppLayout';
import { PerformanceByMember } from '../features/performance/components/PerformanceByMember';
import { PerformanceByMission } from '../features/performance/components/PerformanceByMission';
import { Salutation } from '../features/home/components/Salutation';

export const PerformancePage = () => {
	const { t } = useTranslation('performance');
	return (
		<AppLayout>
			<Salutation text={t('performance')} />
			<Text color="#757575" fontSize="16px">
				{t('performance_description')}
			</Text>
			<Tabs mt="8" isLazy>
				<TabList>
					<Tab
						fontWeight={'medium'}
						_selected={{ color: '#4241E4' }}
						fontSize={'16px'}
					>
						{t('by_member')}
					</Tab>
					<Tab
						fontWeight={'medium'}
						_selected={{ color: '#4241E4' }}
						fontSize={'16px'}
					>
						{t('by_mission')}
					</Tab>
				</TabList>
				<TabIndicator
					mt="-1.5px"
					height="2px"
					bg="#4241E4"
					borderRadius="1px"
				/>
				<TabPanels>
					<TabPanel px="0" py="8">
						<PerformanceByMember />
					</TabPanel>
					<TabPanel px="0" py="8">
						<PerformanceByMission />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</AppLayout>
	);
};
