import { Flex, Text } from '@chakra-ui/react';
import { ReactElement } from 'react';

interface CallBarFactProps {
	icon?: ReactElement | null;
	label: string;
	value?: string | number | null;
}

export const CallBarFact = ({ icon, label, value }: CallBarFactProps) => {
	return (
		<Flex alignItems="center" flex={1}>
			<Flex alignItems="center" gap={5}>
				{icon}
				<Flex direction={'column'} gap={1}>
					<Text fontSize="12px" color="#757575" flexShrink={1} noOfLines={1}>
						{label}
					</Text>
					<Text
						fontSize={'14px'}
						fontWeight={'500'}
						flexShrink={1}
						noOfLines={1}
					>
						{value ?? '-'}
					</Text>
				</Flex>
			</Flex>
		</Flex>
	);
};
