import { Avatar, Flex, Text } from '@chakra-ui/react';
import { SchemaMissionPerformanceResponseDto } from '@jam/api-sdk/api';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import CustomTable from '../../../common/components/custom-table/CustomTable';
import { FilteringToolbar } from '../../../common/components/filtering/FilteringToolbar';
import { useGetTeamPerformanceByMissionQuery } from '../../../redux/api/dashboardApi';
import { MissionDetailDots } from '../../../common/components/custom-table/cells/manager/MissionDetailDots';

export const LoadingState = () => {
	return <div>Loading...</div>;
};

export const PerformanceByMission = () => {
	const { t } = useTranslation('performance');
	const { data, isLoading } = useGetTeamPerformanceByMissionQuery();
	const columnHelper =
		createColumnHelper<SchemaMissionPerformanceResponseDto>();

	if (isLoading) {
		return <LoadingState />;
	}

	if (!data || data.length === 0) {
		return <div>No data</div>;
	}

	const tracks = [...new Set(data.map((performance) => performance.trackName))];

	const columns = [
		columnHelper.accessor('missionName', {
			enableSorting: false,
			header: t('performance_by_mission.mission') ?? '',
			cell: (info) => {
				return (
					<Flex alignItems={'center'} gap="2">
						<Avatar size="sm" src={info.row.original.personaImage ?? ''} />
						<Flex direction={'column'}>
							<Text>{info.getValue()}</Text>
							<Text fontSize="12px" color="#757575">
								{info.row.original.personaName}
							</Text>
						</Flex>
					</Flex>
				);
			},
		}),
		columnHelper.accessor('trackName', {
			header: t('performance_by_mission.track') ?? '',
			enableSorting: false,
			cell: (info) => info.getValue() ?? '-',
		}),
		columnHelper.accessor('roundsPlayed', {
			header: t('performance_by_mission.rounds_played') ?? '',
			enableSorting: false,
			cell: (info) => info.getValue() ?? '-',
		}),
		columnHelper.accessor('roundsCompleted', {
			header: t('performance_by_mission.completions_rounds') ?? '',
			enableSorting: false,
			cell: (info) => `${info.getValue()} / ${info.row.original.roundsPlayed}`,
		}),
		columnHelper.accessor('averageScore', {
			header: t('performance_by_mission.average_score') ?? '',
			enableSorting: false,
			cell: (info) => info.getValue() ?? '-',
		}),
		columnHelper.accessor('averageRoundToCompletion', {
			header: t('performance_by_mission.rounds_to_complete') ?? '',
			enableSorting: false,
			cell: (info) => info.getValue() ?? '-',
		}),
		columnHelper.display({
			id: 'dots',
			cell: ({ row }) => {
				return <MissionDetailDots missionId={row.original.missionId} />;
			},
		}),
	];

	return (
		<CustomTable<
			SchemaMissionPerformanceResponseDto,
			{ missionName: string; trackName: string }
		>
			renderFilterBar={(columnFilters, setColumnFilters) => (
				<FilteringToolbar
					filters={[
						{
							id: 'missionName',
							type: 'text',
							placeholder:
								t('performance_by_mission.search_mission_placeholder') ?? '',
							onChange: (value) =>
								setColumnFilters({ ...columnFilters, missionName: value }),
							value: columnFilters.missionName ?? '',
						},
						{
							id: 'trackName',
							type: 'select',
							options: [
								{
									label: t('performance_by_mission.all_tracks'),
									value: '',
								},
								...tracks.map((track) => ({
									label: track,
									value: track,
								})),
							],
							onChange: (value) =>
								setColumnFilters({ ...columnFilters, trackName: value }),
							value: columnFilters.trackName ?? '',
						},
					]}
				/>
			)}
			withWrapper
			data={data}
			columns={columns}
			paginationPosition="bottom"
		/>
	);
};
