import { useTranslation } from 'react-i18next';
import { StoryStatus } from '../../hooks/useStoryStatus';
import { Chip } from '../Chip';

const StoryStatusToStyleMap: Record<
	StoryStatus,
	{ bg: string; color: string }
> = {
	[StoryStatus.COMPLETED]: { bg: '#EAFDEE', color: '#147129' },
	[StoryStatus.IN_PROGRESS]: { bg: '#EFF8FF', color: '#175CD3' },
	[StoryStatus.NOT_STARTED]: { bg: '#FAFAFA', color: '#383C3E' },
};

export const StoryCardImageHeader = ({
	status,
	hideStatus,
}: {
	status?: StoryStatus;
	hideStatus?: boolean;
}) => {
	const { t } = useTranslation(['story', 'history']);

	const renderContent = () => {
		if (!status || hideStatus) {
			return null;
		} else {
			const { bg, color } = StoryStatusToStyleMap[status];
			return (
				<Chip
					h="24px"
					bg={bg}
					borderRadius={'24px'}
					border={0}
					fontSize={'14px'}
					gap={'4px'}
					color={color}
					fontWeight={'medium'}
					text={t(status)}
				/>
			);
		}
	};

	return <>{renderContent()}</>;
};
