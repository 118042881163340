import { SessionResponseDTOAnalysisItemsSolved } from '@jam/api-sdk/api';
import { useGetSessionsByUserIdQuery } from '../../../redux/api/sessionApi';

/**
 * Represents a user's performance data for a specific round
 */
export type UserPerformanceByRound = {
	round: number;
	session?: {
		id: string;
		userId: string;
		date: string;
		totalItems: number;
		completedItems: number;
	};
	score: number | null;
};

/**
 * Hook to get a user's average performance scores grouped by round number
 * @param userId - The ID of the user to get performance data for
 * @param missionId - The ID of the mission to get performance data for
 * @returns Array of performance data by round, undefined if loading, or null if no data
 */
export const useGetUserPerformanceByRound = (
	userId: string | undefined,
	missionId: string | undefined
): UserPerformanceByRound[] | undefined | null => {
	// Fetch user's sessions data
	const { data, isLoading } = useGetSessionsByUserIdQuery(userId ?? '', {
		skip: !userId,
	});
	if (isLoading) return undefined;
	if (!data) return null;
	if (!userId) return null;

	// If missionId is provided, handle it differently
	if (missionId) {
		// Filter and sort sessions for the specific mission
		const missionSessions = data
			.filter((x) => x.storyReferenceId === missionId)
			.sort(
				(a, b) =>
					new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
			);

		// Create array of first 5 attempts
		const result: UserPerformanceByRound[] = Array(5)
			.fill(null)
			.map((_, index) => ({
				round: index + 1,
				session: {
					id: missionSessions[index]?.id,
					userId,
					date: missionSessions[index]?.createdAt,
					totalItems: missionSessions[index]?.analysis.items.length || 0,
					completedItems:
						missionSessions[index]?.analysis.items.filter(
							(x) => x.solved === SessionResponseDTOAnalysisItemsSolved.solved
						).length || 0,
				},
				score: missionSessions[index]?.analysis.score ?? null,
			}));

		return result;
	}

	// Sort sessions by date, oldest first
	const sortedSessions = data.slice().sort((a, b) => {
		return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
	});

	// Group sessions by storyReferenceId to track attempts per story
	const sessionsByStory = new Map<string, { round: number; score: number }[]>();

	// Populate the sessions map with scores for each story attempt
	sortedSessions.forEach((session) => {
		const storyId = session.storyReferenceId;
		if (!sessionsByStory.has(storyId)) {
			sessionsByStory.set(storyId, []);
		}
		const storySessions = sessionsByStory.get(storyId);
		if (!storySessions) return;

		storySessions.push({
			round: storySessions.length + 1, // Increment round number for each attempt
			score: session.analysis.score,
		});
	});

	// Calculate average scores for each round across all stories
	const scoresByRound = new Map<number, { total: number; count: number }>();
	let maxRound = 0;

	sessionsByStory.forEach((sessions) => {
		sessions.forEach(({ round, score }) => {
			maxRound = Math.max(maxRound, round);
			if (!scoresByRound.has(round)) {
				scoresByRound.set(round, { total: 0, count: 0 });
			}
			const roundData = scoresByRound.get(round);
			if (!roundData) return;
			roundData.total += score;
			roundData.count += 1;
		});
	});

	// Convert map of scores to array of averages per round, ensuring minimum 5 elements
	const result: UserPerformanceByRound[] = [];
	for (let round = 1; round <= Math.max(5, maxRound); round++) {
		const roundData = scoresByRound.get(round);
		if (roundData && roundData.count > 0) {
			result.push({
				round,
				score: roundData.total / roundData.count,
			});
		} else {
			result.push({
				round,
				score: null,
			});
		}
	}

	return result;
};
